body {
  /* spacings definition */
  --spacing-xtiny: 6px;
  --spacing-tiny: 8px;
  --spacing-xsmall: 12px;
  --spacing-small: 16px;
  --spacing-medium: 20px;
  --spacing-large: 24px;
  --spacing-xlarge: 28px;
  --spacing-xlarge: 32px;
  --spacing-xxlarge: 36px;

  /* border radius */
  --border-radius-xxsmall: 2px;
  --border-radius-xsmall: 4px;
  --border-radius-small: 5px;
  --border-radius-medium: 8px;
  --border-radius-large: 10px;
  --border-radius-xlarge: 12px;

  /* grey color shades */
  --grey-50: #f9fafb;
  --grey-100: #f3f4f6;
  --grey-200: #e5e7eb;
  --grey-300: #d1d5db;
  --grey-400: #9ca3af;
  --grey-500: #6b7280;
  --grey-600: #4b5563;
  --grey-700: #374151;
  --grey-800: #1f2937;
  --grey-900: #111827;
  --grey-950: #020617;

  /* fonts color */
  --font-primary: var(--grey-950);
  --font-secondary: var(--grey-500);

  /* background color */
  --bg-primary: var(var(--grey-50));

  /* pink color shade */
  --pink-50: #fce4ec;
  --pink-100: #f8bbd0;
  --pink-200: #f48fb1;
  --pink-300: #f06292;
  --pink-400: #ec407a;
  --pink-500: #e91e63;
  --pink-600: #d81b60;
  --pink-700: #c2185b;
  --pink-800: #ad1457;
  --pink-900: #880e4f;
  --pink-1000: #640e51;

  /* yellow color shade */
  --yellow-50: #fffde7;
  --yellow-100: #fffef7;
  --yellow-200: #fff59d;
  --yellow-300: #fff176;
  --yellow-400: #ffee58;
  --yellow-500: #ffeb3b;
  --yellow-600: #fdd835;
  --yellow-700: #fbc02d;
  --yellow-800: #f9a825;
  --yellow-900: #f57f17;
  --yellow-1000: #f8fd08;

  /* font family */

  --family-appropriate: "Inter";
}
