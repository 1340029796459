* {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Pathway Extreme", sans-serif !important;
  background-color: white;
}
.link {
  text-decoration: none;
  color: inherit;
}
.center-button-portal {
  display: flex;
  justify-content: center;
  align-items: center;
}

a:focus {
  outline: none;
}
